<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />

 <div class="p-10">
  <div class="bg-white shadow overflow-hidden">
   <div class="px-4 py-5 sm:px-6">
    <h3 :class="`text-lg uppercase leading-6 font-medium encom_dark_text text-gray-900`">
     {{ routingListDetail.listName }}
    </h3>
   </div>
   <div class="border-t border-gray-200">
    <div v-show="closeForm">
     <DestinationForm
      @toggle-add-destination="showForm()"
      :availableDestinations="this.intoArray(availableDestinations)"
      :account="this.account"
      :id="this.id"
      @activeBanner="
       (activeBanner = true), getRoutingListDetail(), getAvailableDestination(), (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
     />
    </div>

    <dl v-show="!closeForm">
     <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="font-medium text-gray-500">{{ $t("idList") }}</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
       {{ routingListDetail.listId }}
      </dd>
     </div>
     <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="font-medium text-gray-500">
       {{ $t("geographicalDataModel") }}
      </dt>
      <dd
       v-if="this.routingListDetail.geographicModel === 1"
       class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
      >
       {{ $t("frenchDepartments") }}
      </dd>
     </div>
     <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="font-medium text-gray-500">{{ $t("geographicalRoutesList") }}</dt>

      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
       {{ this.intoArray(routingListDetail.allDestinations) }}
      </dd>
     </div>
     <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="font-medium text-gray-500">{{ $t("geographicalRoutesAvailable") }}</dt>

      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
       {{ this.intoArray(availableDestinations) }}
      </dd>
     </div>

     <div class="bg-gray-50 px-4 py-5 sm:px-6">
      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
       <div class="grid-start-1">
        <dt class="font-medium text-gray-500">{{ $t("geographicalDestinationRoutes") }}</dt>
       </div>
       <div class="grid-start-2 text-right pb-3">
        <button
         v-show="
          userPermissions.permissions.includes('smart-routings.*') ||
          userPermissions.permissions.includes('smart-routings.create')
         "
         @click="showForm()"
         type="button"
         :class="`rounded inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium body__table--header text-white focus:outline-none`"
        >
         <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
         >
          <path
           stroke-linecap="round"
           stroke-linejoin="round"
           stroke-width="2"
           d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
         </svg>
         {{ $t("addDestination") }}
        </button>
       </div>
      </div>
      <div class="p-5" v-if="this.openEditForm">
       <UpdateDestinationRouteForm
        @closeForm="this.openEditForm = false"
        :id="this.routeId"
        :name="this.routeName"
        @activeBanner="
         (activeBanner = true), getRoutingListDetail(), getAvailableDestination(), (key = !key)
        "
        @successMsg="successMsg = $event"
        @success="isSuccess = $event"
       />
      </div>
      <dd
       v-for="list in routingListDetail.geo_routing_destinations"
       :key="list.id"
       class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
      >
       <ul class="border border-gray-200 divide-y divide-gray-200">
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
         <div class="w-0 flex-1 flex items-center">
          <span v-if="list.destination1" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">First Destination</h1>
           {{ list.destination1 }}
          </span>
          <span v-else-if="!list.destination1" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">First Destination</h1>
           No destination added
          </span>
          <span v-if="list.nickname" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">Nickname</h1>
           {{ list.nickname }}
          </span>

          <span v-else-if="!list.nickname" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">Nickname</h1>
           No nickname added
          </span>
          <span class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">ID</h1>
           {{ list.id }}
          </span>
          <span v-if="list.geographic" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">Districts</h1>
           {{ list.geographic }}
          </span>

          <span v-else-if="!list.geographic" class="ml-2 flex-1 w-0 truncate">
           <h1 class="font-bold">Districts</h1>
           No districts added
          </span>
         </div>
         <UpdateDestinationRoutes
          :account="this.account"
          :id="list.id"
          :nickname="list.nickname"
          @openEditForm="this.openUpdateForm(list.id, list.nickname)"
          @activeBanner="
           (activeBanner = true), getRoutingListDetail(), getAvailableDestination(), (key = !key)
          "
          @successMsg="successMsg = $event"
          @success="isSuccess = $event"
         />
        </li>
       </ul>
      </dd>
      <dd
       class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
       v-if="routingListDetail.geo_routing_destinations.length === 0"
      >
       {{ $t("noListAdded") }}
      </dd>
     </div>
    </dl>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import DestinationForm from "../components/DestinationForm.vue";

import { mapGetters } from "vuex";
import UpdateDestinationRoutes from "../components/UpdateDestinationRoutes.vue";
import UpdateDestinationRouteForm from "../components/UpdateDestinationRouteForm.vue";
import SuccessBanner from "../components/SuccessBanner.vue";
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "RoutingListDetail",
 components: {
  DestinationForm,

  UpdateDestinationRoutes,
  UpdateDestinationRouteForm,
  SuccessBanner,
 },
 props: ["account", "id"],
 data() {
  return {
   tableHeaders: [{ name: "Routing Lists" }, { name: "Actions" }],
   geographicModel: { name: "French Districts" },
   routingListDetail: { geo_routing_destinations: [] },
   availableDestinations: {},
   closeForm: false,
   isLoading: true,
   fullPage: false,
   openEditForm: false,
   routeId: "",
   routeName: "",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   userPermissions,
  };
 },

 methods: {
  openUpdateForm(id, nickname) {
   this.openEditForm = true;
   this.routeId = id;
   this.routeName = nickname;
   window.scrollTo(0, 200);
  },
  async getRoutingListDetail() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${this.id}?customerAccount=${
     this.account
    }`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   try {
    this.$emit("setLoading", false);
    this.routingListDetail = res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
    this.$router.push("/georouting");
   }
  },
  async getAvailableDestination() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${
     this.id
    }/availableDestinations?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   try {
    this.$emit("setLoading", false);
    this.availableDestinations = res.data.response;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
    this.$router.push("/georouting");
   }
  },
  intoArray(dest) {
   let array = String(dest).split(",");
   let arrayNumbers = [];
   for (let index = 0; index < array.length; index++) {
    arrayNumbers.push(array[index]);
   }
   return arrayNumbers.join(", ");
  },
  showForm() {
   this.closeForm = !this.closeForm;
  },
 },
 mounted() {
  this.getRoutingListDetail();
  this.getAvailableDestination();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
.cti_card_padding {
 padding: 30px;
}
</style>
