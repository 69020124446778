<template>
  <div class="z-10 shadow">
    <div class="w-full">
      <form
        @submit.prevent="
          UpdateDestinationRoute(
            this.id,
            'Destination Route updated successfully'
          )
        "
        class="shadow space-y-8 divide-y divide-gray-200 form_padding z-10"
        method="POST"
      >
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div class="pt-5">
              <h3
                v-if="this.nickname"
                class="text-lg leading-6 font-medium text-gray-900"
              >
                Update {{ this.nickname }}
              </h3>
              <h3 v-else class="text-lg leading-6 font-medium text-gray-900">
                Update Destination Route
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Enter new informations below.
              </p>
            </div>
            <br />
            <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
              <div class="space-y-6 sm:space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                >
                  <label
                    for="customer-account"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Districts
                    <cite>(required)</cite>
                  </label>

                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      v-model="putForm.districts"
                      required
                      type="text"
                      name="form-districts"
                      id="form-districts"
                      class="rounded max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
              <div class="space-y-6 sm:space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                >
                  <label
                    for="customer-account"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>

                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      v-model="putForm.nickname"
                      type="text"
                      name="from-nickname"
                      id="form-nickname"
                      :class="
                        `rounded max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300`
                      "
                      :placeholder="this.name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5 z-100">
          <div class="flex justify-end z-100">
            <a
              @click="$emit('closeForm')"
              href="javascript:void(0)"
              class="z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
  components: { ConfirmationDialog },
  props: ["id", "name"],
  data() {
    return {
      account,
      putForm: {
        districts: "",
        nickname: "",
      },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    async UpdateDestinationRoute(id, msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingDestinations/${id}`,
        params: {
          customerAccount: `${this.account}`,
          geographic: `${this.putForm.districts}`,
          nickname: `${this.putForm.nickname}`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          this.putForm.districts = "";
          this.putForm.nickname = "";
          this.closePutForm = !this.closePutForm;
          this.sendMessage(msg);
        })
        .catch((err) => {
          window.alert(
            "Oups, something went wrong.\nReason: " + err.response.statusText
          );
        });
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
